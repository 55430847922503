import React from "react";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserType } from "../types/GeneralTypes";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { fetchUsersRequest, setSelectedUser, updateUserReuqest, deleteUserReuqest, addUserReuqest } from "../slices/user.slice";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { getFileUrl } from "../utils/file.util";
import CircularProgress from "@mui/material/CircularProgress";
import index from "../slices";
import OutlinedInput from "@mui/material/OutlinedInput";
import Pagination from "@mui/material/Pagination";
import {fetchPostsRequest} from "../slices/post.slice";
import Stack from "@mui/material/Stack";

type ISotryType = {
    user: { users: [UserType]; selectedUser: UserType; status: string,  pagination:{page: number, pages: number}; };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const Users = () => {
    const dispatch = useDispatch<any>();
    const userstore = useSelector((state: ISotryType) => state.user);

    const users = useSelector((state: ISotryType) => state.user.users);
    const user = useSelector((state: ISotryType) => state.user.selectedUser);
    const pagination = useSelector((state: ISotryType) => state.user.pagination);

    const [isAdd, setIsAdd] = React.useState(false);
    const [viewDetails, setIsViewDetails] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [first_name, setFirstName] = React.useState(user != null ? user.first_name : "");
    const [last_name, setLastName] = React.useState(user != null ? user.last_name : "");
    const [email, setEmail] = React.useState(user != null ? user.email : "");
    const [avatar_file_name, setAvatarFileName] = React.useState("");
    const [avatar, setAvatar] = React.useState<any | null>(null);
    const [file3d, setFile3d] = React.useState<any | null>(null);
    const [bodyfile, setBodyFIle] = React.useState<any | null>(null);
    const [bodyFIleName, setBodyFIleName] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [file3dname, setFile3dname] = React.useState("");
    const [isGuest, setIsGuest] = React.useState(false);
    const [tent_no, setTentNo] = React.useState("");

    const [frineds, setFriends] = React.useState<string[]>([]);
    const handleFriendChange = (event: SelectChangeEvent<typeof frineds>) => {
        const {
            target: { value },
        } = event;
        setFriends(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };
    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
    };

    const handleCapture = ({ target }: any) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        setAvatarFileName(target.files[0].name);
        setAvatar(target.files[0]);
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    }));
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleIsGolfer = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsGuest(event.target.checked);
    };
    useEffect(() => {
        dispatch(fetchUsersRequest(1));
    }, [dispatch]);

    useEffect(() => {
        if (userstore.status == "succeeded") {
            handleClose();
        }
    }, [userstore.status]);

    useEffect(() => {
        if (user != null) {
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setEmail(user.email);
            setIsGuest(user.is_guest == 1)
            setTentNo(user.tent_no)
            var a = [];
            if (user.friends){
                for (var i = 0; i < user.friends.length; i++) {
                    const v = user.friends[i];
                    if (v.user != null) {
                        a.push("" + v.user.ID)
                    }
                }
            }

            setFriends(a);
        }
    }, [user]);

    const UserRow = (props: { row: UserType; index: string; isViewOnly: Boolean }) => {
        const row = props.row;
        return (
            <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                    {row.ID}
                </StyledTableCell>
                <StyledTableCell align="left">{row.first_name + " " + row.last_name}</StyledTableCell>
                <StyledTableCell align="left">{row.email}</StyledTableCell>
                <StyledTableCell align="left">
                    {row.profile_image ?
                        <img style={{width: 70}} src={getFileUrl(row.profile_image, "talentbook-private")}
                             alt="profile image"/> : "No Image" }

                </StyledTableCell>
                {!props.isViewOnly && (
                    <StyledTableCell align="right">
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                dispatch(setSelectedUser({ user: row }));
                                setIsViewDetails(true);
                            }}
                        >
                            <PreviewIcon />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                dispatch(setSelectedUser({ user: row }));
                                setIsAdd(false);
                                setOpen(true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            onClick={() => {
                                if (window.confirm("Are you sure you wish to delete this item?")) {
                                    dispatch(deleteUserReuqest({ user: row }));
                                }
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </StyledTableCell>
                )}
            </StyledTableRow>
        );
    };
    const UserList = (props: { users: [UserType]; isViewOnly: Boolean; index: string }) => {
        return (
            <>
                <TableContainer component={Paper}></TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="left">Name</StyledTableCell>
                            <StyledTableCell align="left">Email</StyledTableCell>
                            <StyledTableCell align="left">Profile</StyledTableCell>

                            {!props.isViewOnly && <StyledTableCell align="right">Actions</StyledTableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.users.map((row) => (
                            <UserRow
                                row={row}
                                index={props.index}
                                isViewOnly={props.isViewOnly}
                                key={row.ID + "user" + props.index + props.isViewOnly.toString()}
                            />
                        ))}
                    </TableBody>
                </Table>
            </>
        );
    };

    return (
        <Box component="main" sx={{ flexGrow: 1 }}>
            <UserList users={users} isViewOnly={false} index={"main"} />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin={10}
            >
            <Stack spacing={2}>
                <Pagination count={pagination.pages} page={pagination.page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                    dispatch(fetchUsersRequest(value));
                }}  variant="outlined" shape="rounded" />
            </Stack>
            </Box>
            {(user != null || isAdd) && (
                <Dialog
                    fullScreen={fullScreen}
                    fullWidth={true}
                    open={open}
                    sx={{ width: "100%", minWidth: 500 }}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{isAdd ? "Add User" : "Edit User"}</DialogTitle>
                    <DialogContent sx={{ width: "100%" }}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                required
                                id="outlined-required"
                                label="First Name"
                                // defaultValue={!isAdd ? user.first_name : ""}
                                value={first_name}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Last Name"
                                // defaultValue={!isAdd ? user.last_name : ""}
                                value={last_name}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                type="email"
                                // defaultValue={!isAdd ? user.email : ""}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={"" + (!isAdd ? status : "")}
                                label="Status"
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            >
                                <MenuItem value="1">Active</MenuItem>
                                <MenuItem value="0">Disable</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <input accept="image/*" style={{ display: "none" }} id="raised-button-file" multiple type="file" onChange={handleCapture} />
                            <FormLabel htmlFor="raised-button-file">{avatar_file_name}</FormLabel>
                            <FormLabel htmlFor="raised-button-file">
                                You have to upload square image if you are not manually update
                                <Button component="span">Upload Profile image</Button>
                            </FormLabel>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                if (isAdd) {
                                    // dispatch(addUserReuqest({ user: { name: value } }));
                                } else {
                                    if (first_name.length > 0 && last_name.length > 0 && email.length > 0) {
                                        dispatch(
                                            updateUserReuqest({
                                                user: {
                                                    profile_image: avatar,
                                                    email,
                                                    last_name: last_name,
                                                    first_name: first_name,
                                                    ID: user.ID,
                                                    status: status,
                                                },
                                            })
                                        );
                                    } else {
                                        alert("you need to fill all required fields");
                                    }
                                }
                            }}
                            autoFocus
                        >
                            {isAdd ? "Add" : "Update"}
                        </Button>
                        {userstore.status == "loading" && (
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "red",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                }}
                            />
                        )}
                    </DialogActions>
                </Dialog>
            )}

            {(user != null || viewDetails) && (
                <Dialog
                    fullScreen={false}
                    fullWidth={true}
                    open={viewDetails}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "750px", // Set your width here
                            },
                        },
                    }}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">View User</DialogTitle>
                    <DialogContent sx={{ width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <Item>Name:</Item>
                            </Grid>
                            <Grid item xs={7}>
                                <Item>{user.first_name + " " + user.last_name}</Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item>Email:</Item>
                            </Grid>
                            <Grid item xs={7}>
                                <Item>{user.email}</Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item>Status:</Item>
                            </Grid>
                            <Grid item xs={7}>
                                <Item>{user.status == 0 ? "Disable" : "Active"}</Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item>Country:</Item>
                            </Grid>
                            <Grid item xs={7}>
                                <Item>{user.country}</Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item>State:</Item>
                            </Grid>
                            <Grid item xs={7}>
                                <Item>{user.state}</Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item>Suburb:</Item>
                            </Grid>
                            <Grid item xs={7}>
                                <Item>{user.suburb}</Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item>Date of birth:</Item>
                            </Grid>
                            <Grid item xs={7}>
                                <Item>{user.date_of_birth}</Item>
                            </Grid>
                            <Grid item xs={5}>
                                <Item>Profile image:</Item>
                            </Grid>
                            <Grid item xs={7}>
                                <Item>
                                    <img style={{ width: 80 }} src={getFileUrl(user.profile_image, "talentbook-private")} alt="profile image" />
                                </Item>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={() => {
                                setIsViewDetails(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};
