import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UserType } from "../types/GeneralTypes";

export interface IUser {
    users: UserType[];
    status: string;
    error: string;
    selectedUser: UserType | null;
    pagination: {page: number, pages: number,}
}

const init: IUser = {
    users: [],
    status: "",
    error: "",
    selectedUser: null,
    pagination: {page: 1, pages: 1}
};
export const userSlice = createSlice({
    name: "user",
    initialState: init,
    reducers: {
        setStories: (state, action) => {},
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload.user;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUsersRequest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchUsersRequest.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.users = action.payload.data.users;
                state.pagination = action.payload.data.page;
            })
            .addCase(fetchUsersRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(updateUserReuqest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(updateUserReuqest.fulfilled, (state, action) => {
                state.status = "succeeded";
                const objIndex = state.users.findIndex((obj: UserType) => obj.ID === state.selectedUser!.ID);
                const updatedObj = action.payload.data;
                const updatedUsers = [...state.users.slice(0, objIndex), updatedObj, ...state.users.slice(objIndex + 1)];
                state.users = updatedUsers;
            })
            .addCase(updateUserReuqest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(deleteUserReuqest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deleteUserReuqest.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.users.splice(
                    state.users.findIndex((a) => a.ID == action.payload.data.id),
                    1
                );
            })
            .addCase(deleteUserReuqest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(addUserReuqest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(addUserReuqest.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.users = [action.payload.user, ...state.users];
            })
            .addCase(addUserReuqest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            });
    },
});

export const fetchUsersRequest = createAsyncThunk("users/fetch", async (page: number) => {
    try {
        const response = await axios.get("/api/v1/admin/users?page="+ page);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const updateUserReuqest = createAsyncThunk("users/update", async (param: any) => {
    try {
        const formData = new FormData();
        for (let [key, value] of Object.entries(param.user)) {
            formData.append(key, value as any);
            console.log(value, key);
        }

        const response = await axios.put("/api/v1/admin/users/" + param.user.ID, formData);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const deleteUserReuqest = createAsyncThunk("users/delete", async (param: any) => {
    try {
        const response = await axios.delete("/api/v1/admin/users/" + param.user.ID);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const addUserReuqest = createAsyncThunk("users/add", async (param: any) => {
    try {
        const response = await axios.post("/api/v1/users", param.user);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const { setStories, setSelectedUser } = userSlice.actions;

export default userSlice.reducer;
