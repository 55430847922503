import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Copyright } from "../components/Copyright";
import { useDispatch, useSelector } from "react-redux";
import { loginReuqest } from "../slices/auth.slice";
import { useEffect } from "react";
import {useSearchParams} from "react-router-dom";
import {verifyToken} from "../slices/auth.slice";

export const Verify = (props: any) => {
    const dispatch = useDispatch<any>();
    const auth = useSelector((state: any) => state.auth);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token")

    console.log(token);

    useEffect(() => {
        if (token != null &&  token !== "") {
            dispatch(verifyToken({token}));
        }
    }, [dispatch, token]);


    return (
        <Container component="main" >
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                    <img src="logo.png" />
                <Typography component="h1" variant="h5" sx={{ m: 1, fontSize: 30}}>
                    {auth.verified ? "Email Verified" : "Invalid URL or expired"}
                </Typography>
                <Typography component="h1" variant="h1"  sx={{ m: 1, fontSize: 20}}>
                    {auth.verified ? "You can login in via your phone" : "Please check your email. Wrong link or expired"}
                </Typography>
                {auth.verifyType == 2 && <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, width:300, backgroundColor: '#F8A500', borderRadius: 20 }}>
                    resend verification code
                </Button>}
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
};
