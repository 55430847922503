import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ClippedDrawer } from "../components/ClippedDrawer";
import { Home } from "../screens/Home";
import { SignIn } from "../screens/SignIn";
import { Users } from "../screens/Users";
import { getStorageObject } from "../utils/local_storage.util";

import { ProtectedRoute } from "./ProtectedRoute";
import {Verify} from "../screens/Verify";
import {SetPass} from "../screens/SetPass";

export const MainRoute = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/signin" element={<SignIn />} />
                <Route path="/verification" element={<Verify />} />
                <Route path="/set_pass" element={<SetPass />} />

                <Route
                    path="/home"
                    element={
                        <ProtectedRoute isAllowed={getStorageObject("token") != null}>
                            <ClippedDrawer>
                                <Home />
                            </ClippedDrawer>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/"
                    element={
                        <ProtectedRoute isAllowed={getStorageObject("token") != null}>
                            <ClippedDrawer>
                                <Home />
                            </ClippedDrawer>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/posts"
                    element={
                        <ProtectedRoute isAllowed={getStorageObject("token") != null}>
                            <ClippedDrawer>
                                <Home />
                            </ClippedDrawer>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/users"
                    element={
                        <ProtectedRoute isAllowed={getStorageObject("token") != null}>
                            <ClippedDrawer>
                                <Users />
                            </ClippedDrawer>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};
