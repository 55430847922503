export const saveUserObject = (user: string, token: string) => {
  if (user !== undefined || user !== '') {
    localStorage.setItem('user', user);
  }
  if (token !== undefined || token !== '') {
    localStorage.setItem('token', token);
  }
};

export const getStorageObject = (key: string) => {
  return localStorage.getItem(key);
};
