import { combineReducers } from "@reduxjs/toolkit";
import axios from "axios";

import AuthSlice from "./auth.slice";
import EventSlice from "./post.slice";
import UserSlice from "./user.slice";

const index = (history: any) => {
    return combineReducers({
        auth: AuthSlice,
        post: EventSlice,
        user: UserSlice,
    });
};

axios.defaults.baseURL = process.env.REACT_APP_HOST;

export default index;
