import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {Copyright} from "../components/Copyright";
import {useDispatch, useSelector} from "react-redux";

import {useSearchParams} from "react-router-dom";
import {changePassword, resetStatus} from "../slices/auth.slice";
import {useEffect} from "react";

export const SetPass = (props: any) => {
    const dispatch = useDispatch<any>();
    const auth = useSelector((state: any) => state.auth);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token")

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if ( data.get("confirm_password")!.toString() !==  data.get("password")!.toString()) {
            alert("Password Doesn't match");
            return;
        }
        dispatch(changePassword({ token: token, password: data.get("confirm_password")!.toString(), type: 'set_password' })).unwrap();
    };

    useEffect(() => {
        dispatch(resetStatus({}));
    },[]);

    return (
        <Container component="main">
            <CssBaseline/>
            <Box component="form" onSubmit={handleSubmit} noValidate
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img src="logo.png"/>
                <TextField id="password" name="password"  label="New password" style={{width: 400, marginTop: 50}} type="password"
                           variant="outlined" InputProps={{
                    style: {
                        borderRadius: "30px",
                    }
                }}/>
                <TextField id="confirm_password" name="confirm_password" label="Confirm Password" style={{width: 400, marginTop: 30}} type="password"
                           variant="outlined" InputProps={{
                    style: {
                        borderRadius: "30px",
                    }
                }}/>
                {auth.status === "succeeded" &&  auth.pass_changed ? <Typography component="h1" variant="h1"  sx={{ m: 1, fontSize: 20}}>Please try on your mobile with new password</Typography> : (auth.status === "") ? '' : <Typography component="h1" variant="h1"  sx={{ m: 1, fontSize: 20}}>Invalid information</Typography> }
                <Button type="submit" variant="contained" style={{
                    width: 400, backgroundColor: "#F7A500", borderRadius: 35, marginTop: 30,
                    padding: "18px 36px",
                }}>Send</Button>

            </Box>
            <Copyright sx={{mt: 8, mb: 4}}/>
        </Container>
    );
};
