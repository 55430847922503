import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { PostType } from "../types/GeneralTypes";

export interface IPost {
    posts: PostType[];
    status: string;
    error: string;
    selectedPost: PostType | null;
    pagination: {page: number, pages: number,}
}

const init: IPost = {
    posts: [],
    status: "",
    error: "",
    selectedPost: null,
    pagination: {page: 1, pages: 1}
};
export const postSlice = createSlice({
    name: "post",
    initialState: init,
    reducers: {
        setStories: (state, action) => {},
        setSelectedPost: (state, action) => {
            state.selectedPost = action.payload.post;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPostsRequest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchPostsRequest.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.posts = action.payload.data.posts;
                state.pagination = action.payload.data.page;
            })
            .addCase(fetchPostsRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(updatePostRequest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(updatePostRequest.fulfilled, (state, action) => {
                state.status = "succeeded";
                const objIndex = state.posts.findIndex((obj: PostType) => obj.ID === state.selectedPost!.ID);
                const updatedObj = { ...state.posts[objIndex], name: action.payload.post.name };
                const updatedposts = [...state.posts.slice(0, objIndex), updatedObj, ...state.posts.slice(objIndex + 1)];
                state.posts = updatedposts;
            })
            .addCase(updatePostRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(deletePostRequest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deletePostRequest.fulfilled, (state, action) => {
                state.status = "succeeded";
                // state.posts.splice(
                //     state.posts.findIndex((a) => a.ID == action.payload.id),
                //     1
                // );
                const arr = state.posts.filter((arrow) => "" + arrow.ID !== action.payload.data.id);
                state.posts = arr;
            })
            .addCase(deletePostRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(addPostRequest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(addPostRequest.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.posts = [action.payload.post, ...state.posts];
            })
            .addCase(addPostRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(duplicatePostRequest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(duplicatePostRequest.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.posts = [action.payload.post, ...state.posts];
            })
            .addCase(duplicatePostRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(deletePostAssetRequest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(deletePostAssetRequest.fulfilled, (state, action) => {
                state.status = "succeeded";
                // @ts-ignore
                const arr = state.selectedPost.PostAssets.filter((arrow) => "" + arrow.ID !== action.payload.data.id);
                const p = state.selectedPost;
                // @ts-ignore
                p.PostAssets = arr;
                state.selectedPost = p

                const objIndex = state.posts.findIndex((obj: PostType) => obj.ID === state.selectedPost!.ID);
                const updatedObj = p
                const updatedposts = [...state.posts.slice(0, objIndex), updatedObj, ...state.posts.slice(objIndex + 1)];
                // @ts-ignore
                state.posts = updatedposts;
            })
            .addCase(deletePostAssetRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            });;
    },
});

export const fetchPostsRequest = createAsyncThunk("posts/fetch", async (page: number) => {
    try {
        const response = await axios.get("/api/v1/admin/posts?page=" + page);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const updatePostRequest = createAsyncThunk("posts/update", async (param: any) => {
    try {
        const response = await axios.put("/api/v1/admin/posts/" + param.post.ID, param.post);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const deletePostRequest = createAsyncThunk("posts/delete", async (param: any) => {
    try {
        const response = await axios.delete("/api/v1/admin/posts/" + param.post.ID);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const duplicatePostRequest = createAsyncThunk("posts/duplicate", async (param: any) => {
    try {
        const response = await axios.post("/api/v1/admin/posts/" + param.post.ID);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const addPostRequest = createAsyncThunk("posts/add", async (param: any) => {
    try {
        const response = await axios.post("/api/v1/posts", param.post);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const deletePostAssetRequest = createAsyncThunk("assets/delete", async (param: any) => {
    try {
        const response = await axios.delete("/api/v1/admin/assets/" + param.id);
        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const { setStories, setSelectedPost } = postSlice.actions;

export default postSlice.reducer;
