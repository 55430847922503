import React from "react";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostType } from "../types/GeneralTypes";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
    fetchPostsRequest,
    setSelectedPost,
    updatePostRequest,
    deletePostRequest,
    addPostRequest,
    duplicatePostRequest,
    deletePostAssetRequest
} from "../slices/post.slice";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {setSelectedUser} from "../slices/user.slice";
import {getFileUrl} from "../utils/file.util";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

type ISotryType = {
    post: {
        posts: [PostType];
        selectedPost: PostType;
        status: string ;
        pagination:{page: number, pages: number};
    };
};

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export const Home = () => {
    const dispatch = useDispatch<any>();
    const poststore = useSelector((state: ISotryType) => state.post);

    const posts = useSelector((state: ISotryType) => state.post.posts);
    const post = useSelector((state: ISotryType) => state.post.selectedPost);
    const pagination = useSelector((state: ISotryType) => state.post.pagination);

    const [isAdd, setIsAdd] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [value, setValue] = React.useState("");

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        dispatch(fetchPostsRequest(1));
    }, [dispatch]);

    useEffect(() => {
        if (poststore.status == "succeeded") {
            handleClose();
        }
    }, [poststore.status]);

    return (
        <Box component="main" sx={{ flexGrow: 1 }}>
            <TableContainer component={Paper}>
            </TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell align="left">Name</StyledTableCell>
                        <StyledTableCell align="right">Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {posts.map((row) => (
                        <StyledTableRow key={row.ID}>
                            <StyledTableCell component="th" scope="row">
                                {row.ID}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.text}</StyledTableCell>
                            <StyledTableCell align="right">
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        dispatch(setSelectedPost({ post: row }));
                                        setIsAdd(false);
                                        setOpen(true);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>

                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        if (window.confirm("Are you sure you wish to delete this post?")) {
                                            dispatch(deletePostRequest({ post: row }));
                                        }
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin={10}
            >
            <Stack spacing={2}>
                <Pagination count={pagination.pages} page={pagination.page} onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                    dispatch(fetchPostsRequest(value));
                }}  variant="outlined" shape="rounded" />
            </Stack>
            </Box>
            {(post != null || isAdd) && (
                <Dialog
                    fullScreen={fullScreen}
                    fullWidth={true}
                    open={open}
                    sx={{ width: "100%", minWidth: 500 }}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{isAdd ? "Add Post" : "Edit Post"}</DialogTitle>
                    <DialogContent sx={{ width: "100%" }}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Required"
                                defaultValue={!isAdd ? post.text : ""}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 1, marginTop:10 }}>
                            <Grid container spacing={5} justifyContent="center">
                                {post.PostAssets.map((asset) => {
                                    return <Grid item xs={12} md={4} sm={6} key={ 'item-acc' +  asset.ID}><Card>
                                        <div>
                                            <img style={{width: 170}} src={getFileUrl(asset.name, "talentbook-private")}
                                                 alt="asset image"/></div>
                                        <Button autoFocus onClick={() => {
                                            dispatch(deletePostAssetRequest({ id: asset.ID }));
                                        }}>
                                            delete
                                        </Button>
                                    </Card></Grid>
                                })}


                            </Grid>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                if (isAdd) {
                                    dispatch(addPostRequest({ post: { name: value } }));
                                } else {
                                    dispatch(updatePostRequest({ post: { name: value, ID: post.ID } }));
                                }
                            }}
                            autoFocus
                        >
                            {isAdd ? "Add" : "Update"}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};
