import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveUserObject } from "../utils/local_storage.util";
import axios from "axios";
import { useNavigate } from "react-router-dom";


export const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: "",
        user: {},
        status: "",
        error: "",
        verified: false,
        pass_changed: false,
        verifyType: 0
    },
    reducers: {
        setUser: (state, action) => {
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        login: (state, action) => {
            console.log(action.payload);
        },
        resetStatus: (state, action) => {
            state.status = ""
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loginReuqest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(loginReuqest.fulfilled, (state, action) => {
                state.status = "succeeded";
               // console.log(action.payload);
                // state.posts = state.posts.concat(action.payload);
                state.user = action.payload.user;
                state.token = action.payload.token;
                saveUserObject(JSON.stringify(action.payload.user), action.payload.token);
            })
            .addCase(loginReuqest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message!;
            })
            .addCase(verifyToken.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.verified = (action.payload.status && action.payload.status === "success");
                state.verifyType = action.payload.type;
               //     console.log(action.payload);
                // state.posts = state.posts.concat(action.payload);
               // state.user = action.payload.user;
               // state.token = action.payload.token;
               // saveUserObject(JSON.stringify(action.payload.user), action.payload.token);
            })
            .addCase(changePassword.fulfilled, (state, action) => {
            state.status = "succeeded";
            console.log(action.payload);
            state.pass_changed = (action.payload.status && action.payload.status === "success");
            //state.verifyType = action.payload.type;
            //     console.log(action.payload);
            // state.posts = state.posts.concat(action.payload);
            // state.user = action.payload.user;
            // state.token = action.payload.token;
            // saveUserObject(JSON.stringify(action.payload.user), action.payload.token);
        });
    },
});

export const loginReuqest = createAsyncThunk("user/login", async (param: any) => {
    /*
    {
            email: "admin@talentbooks.com",
            password: "wow@1234",
        }
    */
    try {
        const response = await axios.post("/api/v1/admin/login", param);

        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const verifyToken = createAsyncThunk("user/verify", async (param: any) => {

    try {
        const response = await axios.post("/api/v1/mobile/auth/verify", param);

        return response.data;
    } catch (e) {
        console.log(e);
    }
});

export const changePassword = createAsyncThunk("user/set_password", async (param: any) => {

    try {
        const response = await axios.post("/api/v1/mobile/auth/set_password", param);

        return response.data;
    } catch (e) {
        console.log(e);
    }
});



export const { login, resetStatus } = authSlice.actions;

export default authSlice.reducer;
