import { Buffer } from "buffer";

export const getFileUrl = (name: string, bucketName: string) => {
    const t = {
        bucket: bucketName,
        key: name,
    };
    let suffix = convertBase64(JSON.stringify(t));
    let fullUrl = process.env.REACT_APP_CLOUD_FRONT_URL + "/" + suffix;
    return fullUrl;
};
const convertBase64 = (str: string) => {
    return Buffer.from(str).toString("base64");
};
